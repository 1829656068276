.wallet {
    display: flex;
    justify-content: right;
    padding: 20px 0;
    max-width: 960px;
    margin: auto;
}
.wallet button {
    background-color: #2172E5;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 15px;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.wallet button:enabled {
    cursor: pointer;
}

.wallet button:hover:enabled {
    background-color: #1E5CB3;
}

.wallet button .connected {
    background-color: #2172E5;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 15px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;

    background-color: #444; /* Darker background to signify disabled state */
    color: #666; /* Dimmed text color */
    cursor: not-allowed;
  }

.mint-eth-button {
    margin-right: 20px
}

.mint-eth-button:disabled {
    background: #cccccc;
}

.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
/* StrategySection.css */
.strategy-section {
    background-color: #29292D;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .strategy-section h3 {
    color: white;
    margin-bottom: 10px;
  }
  
  .description p {
    color: #AAA;
    margin-bottom: 20px;
  }
  
  .parameters .parameter {
    display: flex;
    justify-content: space-between;
    color: white;
    margin-bottom: 5px;
  }
  
  .parameters .key {
    font-weight: bold;
  }
  
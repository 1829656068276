.guidance-header {
    margin-top: 40px;
    margin-left: 40px;
}

.guidance {
    margin-bottom: 20px;
}

.guidance li {
    margin: 20px
}

table > tr > td {
    padding-left: 20px
}
/* src/App.css */
body, html {
  margin: 0;
  padding: 40px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #4a4a4a; /* Background color for the overall app */
}


/* Responsive design */
@media (max-width: 890px) {
  body, html {
    padding: 40px 20px;
  }
}
/* Responsive design */
@media (max-width: 500px) {
  body, html {
    padding: 0px;
  }
}

.popup {
  width: 952px; /* Width of the popup */
  background-color: #17171A; /* Dark background of the popup */
  color: #fff; /* Text color */
  border-radius: 20px; /* Rounded corners */
  box-shadow: 0 0 20px rgba(0,0,0,0.5); /* Shadow for the popup */
  overflow: hidden; /* Ensures inner elements adhere to border radius */
  margin: 40px auto; /* Centers the popup on the page */
}

/* Inner padding and layout */
.popup-inner {
  padding: 20px 40px;
}

/* Styling for headers and text */
h1, h2, p {
  margin: 0;
  padding: 0;
  color: #FFFFFF;
}

h1 {
  font-size: 1.5em; /* Larger font for main titles */
}

h2 {
  font-size: 1.2em; /* Subtitle sizes */
}

p {
  font-size: 0.9em; /* Paragraph text size */
}

/* Button styles */
.btn {
  background-color: #8A8A8E; /* Default button color */
  border: none;
  border-radius: 15px;
  color: white;
  padding: 10px 20px;
  text-transform: uppercase;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #2172E5; /* Primary button color */
}

.btn-primary:hover {
  background-color: #1E5CB3; /* Button hover state */
}

.btn-close {
  background: transparent;
  color: #fff;
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 1.5em;
  border: none;
  cursor: pointer;
}

/* Tab styles for Deposit/Withdraw buttons */
.tab {
  background-color: #2C2F36; /* Tab background */
  border-radius: 15px;
  display: inline-block;
  margin-right: 5px;
}

.tab:not(.active):hover {
  background-color: #40444F; /* Hover state for tabs */
}

.tab.active {
  background-color: #2172E5; /* Active tab color */
}

/* Input styles */
input[type="text"], input[type="number"] {
  background-color: #2C2F36;
  border: 1px solid #44454B;
  border-radius: 15px;
  color: #fff;
  padding: 10px;
  width: 100%;
  margin: 10px 0;
}

/* Select box styles */
select {
  background-color: #2C2F36;
  border: 1px solid #44454B;
  border-radius: 15px;
  color: #fff;
  padding: 10px;
  width: 100%;
}

/* Divider line style */
.divider {
  border-top: 1px solid #44454B;
  margin: 20px 0;
}



/* WithdrawSection.css */
.withdraw-section {
    background-color: #1F1F23;
    border-radius: 10px;
    padding: 20px;
    color: white;
  }
  
  /* Add styles from depositSection.css as they are quite similar */
  
  .withdraw-header {
    /* display: flex; */
    /* justify-content: space-between; */
    /* align-items: center; */
    margin-bottom: 20px;
  }
  
  .token-select {
    background-color: #29292D;
    border-radius: 10px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    width: 100%;
  }
  
  .token-select img {
    height: 24px; /* Replace with your icon size */
  }
  
  .token-select .down-arrow {
    margin-left: auto; /* Align arrow to the right */
  }
  
  .tabs .tab {
    background-color: transparent;
    color: #AAA;
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    cursor: pointer;
  }
  
  .tabs .tab.active {
    background-color: #2172E5;
    color: white;
    border-radius: 10px;
  }
  
  .withdraw-body label {
    display: block;
    margin-bottom: 10px;
  }
  
  .input-group {
    position: relative;
    margin-bottom: 20px;
  }
  
  .input-group input {
    width: 100%;
    background-color: #29292D;
    border: 1px solid #44454B;
    border-radius: 10px;
    color: white;
    padding: 15px;
    font-size: 1rem;
  }
  
  .input-group button {
    position: absolute; 
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #AAA;
    cursor: pointer;
  }
  
  .conversion {
    font-size: 1rem;
    color: #AAA;
    margin-bottom: 20px;
  }
  
  .withdraw-button {
    width: 100%;
    background-color: #2172E5;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 15px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .withdraw-button:hover {
    background-color: #1E5CB3;
  }
  
  /* Additional specific styles for the withdrawal section */
  .available-balance {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    /* margin-bottom: 20px; */
    font-size: 1rem;
    color: #AAA;
  }
  
  .withdraw-body .expected, .withdraw-body .fee {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    color: #AAA;
  }
  
  .withdraw-button {
    width: 100%;
    background-color: #2172E5;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 15px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .withdraw-button:hover {
    background-color: #1E5CB3;
  }
  
.header {
    color: #fff;
    padding: 20px;
    background-color: #17171A; /* Dark background */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .header .asset-info h1 {
    font-size: 2.5rem; /* Larger font size for ETH */
    font-weight: 700; /* Bold font weight */
    margin-bottom: 0.5rem; /* Spacing between the title and the balance */
  }
  
  .header .asset-info .subtitle-section {
    font-size: 1.2rem; /* Appropriate font size for balance */
    color: #AAAAAA; /* Lighter text color for balance */
    display: flex;
  }

  .subtitle .key {
    font-weight: bold;
  }

  .subtitle {
    margin-right: 40px;
  }

  .subtitle .val .secondary {
    color: #7c7c7c; /* Lighter text color for balance */
  }
  
/* IntegratedProtocolsSection.css */
.integrated-protocols-section {
    background-color: #29292D;
    border-radius: 10px;
    padding-left: 20px;
    /* margin-bottom: 20px; */
  }
  
  .integrated-protocols-section h3 {
    color: white;
    /* margin-bottom: 15px; */
  }
  
  .protocols-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .protocol-label {
    background-color: #1F1F23;
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    padding: 10px 15px;
    gap: 10px;
  }
  
  .protocol-label img {
    height: 20px;
    width: 20px; 
  }
  
  .protocol-label span {
    color: white;
    font-size: 0.9rem;
  }
  
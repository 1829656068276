/* ActionsSection.css */
.actions {
    background-color: #1F1F23;
    border-radius: 10px;
    padding: 20px;
    color: white;
  }

.actions-section {
    background-color: transparent; /* No background to let the main popup color show */
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the tabs */
  }
  
  .tabs {
    display: flex;
    justify-content: center;
    gap: 10px; /* Space between tabs */
  }
  
  .tab {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s; /* Smooth transitions for interaction */
    font-weight: bold; /* Bold font for tab text */
  }
  
  .tab:not(.active) {
    background-color: #333; /* Non-active tab color */
    color: #aaa; /* Non-active tab text color */
  }
  
  .tab.active {
    background-color: #2172E5; /* Active tab color */
    color: white;
    transform: scale(1.1); /* Slightly scale active tab for emphasis */
  }
  
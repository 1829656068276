/* VaultPopup.css */
.popup {
  background-color: #29292D;
  border-radius: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  flex-direction: column;
  max-width: 960px;
  width: 100%; /* Add this to make width responsive */
  margin: auto;
}

.content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.stats,
.actions {
  margin: 10px; /* Add margin for spacing */
}

.actions {
  min-width: 400px;
}

/* Responsive design */
@media (max-width: 890px) {
  .content {
    flex-direction: column;
  }

  .vault-info {
    width: 100%; /* Full width on small screens */
    margin: 10px 0; /* Adjust margin for vertical layout */
  }

  .actions {
    min-width: 300px;
  }
}
